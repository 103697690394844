<template>
  <div class="productService">
    <div class="product-topImg"></div>

    <div class="case center">
      <el-card style="margin-top: 20px;padding-bottom:110px">
        <div>
          <div class="title">为什么选择瑞懿科技</div>
          <div class="line">
            <i></i>
          </div>
        </div>
        <div class="case-items">
          <div class="content-right1"></div>
          <div class="content-left">
            <div
              class="case-word"
            >瑞懿具有深厚的国土资源行业背景、扎实的土地等不动产数据积累及丰富的系统开发建设经验，凭借多学科、多领域融合优势和专业数据挖掘技术，深入挖掘和创造不动产信息的价值。</div>
          </div>
        </div>
        <div class="case-items">
          <div class="content-left">
            <div
              class="case-word"
            >公司提供风控管理等系统研发、不动产数据分析和评估（评价）类咨询服务在内的多种服务类型，满足政府、企业、金融机构、社会公众所需各类应用场景，并支持个性定制服务及二次开发服务等，快速响应用户需求，助力用户智慧化转型。</div>
          </div>
          <div class="content-right2"></div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>

<style scoped>
.productService {
  width: 100%;
}
.product-topImg {
  width: 100%;
  height: 308px;
  background-image: url("../../assets/image/合作案例.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.case {
  padding: 70px 0px 90px 0px;
}
.case-items {
  display: flex;
  justify-content: space-between;
  padding: 21px 0;
}
.content-left {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.case-word {
  /* width: 100%; */
  width: 500px;
  height: 96px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #666666;
  margin-top: 26px;
  /* display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden; */
}

.content-right1 {
  height: 328px;
  width: 50%;
  /* margin-right: 44px; */
  background-image: url("../../assets/image/合作案例1 (1).png");
  background-repeat: no-repeat;
  background-size: 400px 328px;
  background-position: center;
}
.content-right2 {
  width: 50%;
  height: 326px;
  /* margin-right: 44px; */
  background-image: url("../../assets/image/合作案例1 (2).png");
  background-repeat: no-repeat;
  background-size: 400px 326px;
  background-position: center;
}
.title {
  height: 50px;
  font-size: 38px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 50px;
  color: #333333;
  opacity: 1;
  text-align: center;
  margin-top:40px
}
.line{
  text-align: center;
  margin-top:28px;
  margin-bottom: 21px;
}
.line i{
  display: inline-block;
  width: 132px;
  height: 16px;
  background: #00377e;
  border: 1px solid #707070;
  opacity: 1;
  border-radius: 6px;
}
</style>